@import '../../sass/variables';


.fieldmanual {
  height: 100%;
  width: 100%;
  padding: 3rem;

  &-header {
    text-align: center;
    margin-bottom: 2rem;
  }

  &-main {
    height: 100%;
    width: 100%;
    overflow-y: auto;

    &-item {
      position: relative;
      display: flex;
      flex-direction: column;
      list-style: none;
      margin: 2rem 0;

      &-title {
        background-color: $btn-color-primary;
        padding-left: 0.5rem;
        width: 100%;
      }

      &-description {
        font-size: 1.5rem;
        margin-bottom: 1rem;
      }

      &-textlist {
        list-style: none;

        &-item {
          padding-left: 2rem;
          margin-bottom: 1rem;

          &-sublist {
            list-style: inside;
            padding-left: 2rem;
          }
        }

      }
    }
  }
}