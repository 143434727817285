@import '../../sass/variables';

.timemodal {
  position: fixed;
  bottom: 30%;
  left: 20%;
  z-index: 99999;
  background-color: $btn-color-primary;
  border: 0.1rem #000000 solid;
  border-radius: 1rem;
  height: 50rem;
  width: 50rem;
  font-size: 2.4rem;
  text-align: center;

  &-sigcanvas {
    position: relative;
    border: solid 0.2rem #000000;
    width: 80%;
    margin-top: 3rem;
    border-radius: 1rem;
  }

  &-buttons {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 0 10rem;
    margin-top: 5rem;

    &-confirm,
    &-cancel {
      height: 3rem;
      width: 10rem;
    }
  }

}

.doubletimeModal {
  position: fixed;
  bottom: 40%;
  left: 20%;
  z-index: 99999;
  background-color: $btn-color-primary;
  border: 0.1rem #000000 solid;
  height: 40rem;
  width: 50rem;
  font-size: 2.4rem;
  text-align: center;
  padding-top: 2rem;

  &-input {
    position: relative;
    height: 3rem;
    width: 100%rem;
    margin-top: 2rem;
  }

  &-buttons {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 0 10rem;
    margin-top: 5rem;

    &-confirm,
    &-cancel {
      height: 3rem;
      width: 10rem;
    }
  }

}

.notesmodal {
  position: fixed;
  bottom: 40rem;
  left: 19rem;
  z-index: 99999;
  background-color: $btn-color-primary;
  border: 0.1rem #000000 solid;
  height: 50rem;
  width: 50rem;
  font-size: 2.4rem;
  text-align: center;

  &-buttons {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 0 10rem;
    margin-top: 5rem;

    &-save,
    &-cancel {
      height: 3rem;
      width: 10rem;
    }
  }
}

.signaturesModal {
  position: relative;
  bottom: 60rem;
  left: 25rem;
  z-index: 99999;
  background-color: $btn-color-primary;
  border: 0.1rem #000000 solid;
  height: 95rem;
  width: 50rem;
  font-size: 1.8rem;
  text-align: center;
  background-color: #ffffff;
  padding-top: 10rem;

  label {
    margin: 2rem 0 0.5rem 0;
    font-weight: 600;
  }

  &-sigwrapper {

    &-PM {
      margin: 0.5rem auto 3rem auto;
      background-color: #D9D9D9;
      height: 8rem;
      width: 40rem;
      text-align: center;
      padding: 3rem 0;

    }

    &-savebtn {
      position: relative;
      height: 2rem;
      width: 8rem;
      margin: 0 0 1rem 30rem;
    }
  }

  &-sigcanvas {
    background-color: #D9D9D9;
    height: 8rem;
    width: 40rem;
    margin: 0.5rem 0 1rem 0;
  }



  &-buttons {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 0 10rem;
    margin-top: 5rem;

    &-confirm,
    &-cancel {
      height: 3rem;
      width: 10rem;
    }
  }

  &-date {
    margin-top: 10rem;
  }

}